import { useMemo, useState } from "react";
import { useScrollToTop } from "../general/utils";
import { Link } from "react-router-dom";

const DEPS = {
  "@testing-library/jest-dom": "^5.16.2",
  "@testing-library/react": "^12.1.4",
  "@testing-library/user-event": "^13.5.0",
  "bible-passage-reference-parser": "^2.0.1",
  d3: "^7.3.0",
  "node-sass": "^9.0.0",
  react: "^17.0.2",
  "react-dom": "^17.0.2",
  "react-router": "^6.2.2",
  "react-router-dom": "^6.2.2",
  "react-scripts": "5.0.0",
  "web-vitals": "^2.1.4",
};

export function About() {

  const [showContact, setShowContact] = useState(false);
  useScrollToTop()

  const deps = useMemo(() => {
    let out = [];
    for (const k in DEPS) {
      out.push({ type: "JS", name: k });
    }
    return out;
  }, []);

  return (
    <section>
      <Link to="/" className="decorative-font font-medium">
        the Pilgrim's Progress
      </Link>
      <h1 className="decorative-font font-large">About</h1>
      <h3 className="decorative-font font-medium">Software & Sources</h3>
      {deps.map(({ type, name }) => {
        return (
          <div key={name}>
            <span>JavaScript: </span>
            <a href={`https://www.npmjs.com/${name}`} target="_blank">
              {name}
            </a>
          </div>
        );
      })}
      <br />
      <div>
        Bible data from{" "}
        <a
          href="https://github.com/scrollmapper/bible_databases"
          target="_blank"
        >
          scrollmapper/bible_databases
        </a>
      </div>
      <br />
      <div>
        Text from{" "}
        <a
          href="https://www.gutenberg.org/ebooks/131"
          target="_blank"
        >
          Gutenberg
        </a>
      </div>
      <div>
        Icons from{" "}
        <a
          href="https://icons.getbootstrap.com/"
          target="_blank"
        >
          Bootstrap icons
        </a>
      </div>
      <br />
      <br />
      <strong>
        {!showContact && (
          <a href="#" onClick={() => setShowContact(true)}>
            Contact
          </a>
        )}
        {showContact && (
          <a href="mailto:arielakeman+pp@gmail.com">arie email</a>
        )}
      </strong>
    </section>
  );
}
