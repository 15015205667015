// sparklines for locations and characters appearance?
// - could also have a hover-line with nearest appearance to scroll to that appearance

import DIVISIONS from './divisions.json';
import CHARS from './characters.json';

// "HYP.": "Hypocrisy"
export const chars = CHARS;

export const divisions = DIVISIONS;

export const totalMarks = 404;

// - wikipedia has a list, also other summary websites possibly of use
export const extraChars = [
  "Giant Despair",
  "Diffidence",
  "Discretion",
  "Shining Ones",
  "Temporary",
  "Little-faith"
  /* TODO: make case sensitive
  "Adam",
  "Discontent",
  "Shame"
  */
];

//- ...chapter locations - slough of despond etc.
//- ...also listed on wikipedia
export const places = [
  { name: "country of conceit" },
  { name: "county of coveting" },
  { name: "in graceless", display: "Graceless (town)" },
  { name: "land of vain-glory" },
  { name: "fair-speech" },
  { name: "good-confidence" },
  { name: "love-gain" },
  { name: "apostasy" },
  { name: "carnal policy" },
  { name: "Beautiful", caseSensitive: true },
  { name: "Honesty", caseSensitive: true },
  { name: "town of sincere" },
  { name: "Immanuel's land" },
  { name: "called clear", display: "high hill called clear" },
  { name: "hill called difficulty" },
  { name: "hill called error" },
  { name: "hill called lucre" }, // - silver mine, Demas' },
  { name: "enchanted ground" },
  { name: "beulah" },
  { name: "broad-way gate" },
  { name: "plain called ease" },
  { name: "named morality", display: "village named morality" },
];

export const mapPlaces = [
  { name: 'City of Destruction', mark: 0, asset: 'city1' },
  { name: 'Slough of Despond', mark: 28, asset: 'bog' },
  { name: "Interpreter's House", mark: 62, asset: 'house1' }, // has wicket gate
//  { name: 'Mt. Sinai', mark: 46, asset: 'mountain' },
  { name: 'Place of Deliverance', mark: 93, asset: 'cross' },
//  { name: 'Hill of Difficulty', mark: 103 },
  { name: 'Valley', mark: 160, asset: 'valley' },
  { name: 'House Beautiful', mark: 114, iconOnly: true, asset: 'house2' },
  { name: 'Vanity Fair', mark: 219, asset: 'market' },
  { name: 'Doubting Castle', mark: 283, asset: 'castle2' },
//  { name: 'Delectable Mountains', mark: 295, asset: 'hills2' },
  { name: 'Celestial City', mark: 404, asset: 'citadel' }
]

const _ = `
1. {10} City of Destruction [leaving the city]
 - city
 - slough of despond
 - wicket gate
 - mt Sinai
2. {62} the Interpreter's House [could structure as many individual visions, experience each separately]
 - Interpreter's House (dream within a dream!?)
	- picture on wall
	- dusty parlour
	- children, passion and patience
	- fire, oil
	- palace
	- man in an iron cage
	- man dreaming of day of judgement
 - Hill of Difficulty
	- danger and destruction - forest/wood and mountains
3. {141} Apollyon
 - Apollyon
 - Valley
4. {169} Conversation with Faithful
 - see titles below
 - also dream within dream, characters?
5. {215} Vanity Fair
 - the Fair
 - Trial
6. {266} Doubting Castle
 - Arrival
 - Escape
7. {295} Delectable Mountains
8. {338} Conversation on Enchanted Ground
  - see title below
  - also dream within dream?
9. {382} the Conclusion
 - River
 - Celestial City
 `;

export const BibleBooks = [
  "Genesis",
  "Exodus",
  "Leviticus",
  "Numbers",
  "Deuteronomy",
  "Joshua",
  "Judges",
  "Ruth",
  "1 Samuel",
  "2 Samuel",
  "1 Kings",
  "2 Kings",
  "1 Chronicles",
  "2 Chronicles",
  "Ezra",
  "Nehemiah",
  "Esther",
  "Job",
  "Psalm",
  "Proverbs",
  "Ecclesiastes",
  "Song of Solomon",
  "Isaiah",
  "Jeremiah",
  "Lamentations",
  "Ezekiel",
  "Daniel",
  "Hosea",
  "Joel",
  "Amos",
  "Obadiah",
  "Jonah",
  "Micah",
  "Nahum",
  "Habakkuk",
  "Zephaniah",
  "Haggai",
  "Zechariah",
  "Malachi",
  "Matthew",
  "Mark",
  "Luke",
  "John",
  "Acts",
  "Romans",
  "1 Corinthians",
  "2 Corinthians",
  "Galatians",
  "Ephesians",
  "Philippians",
  "Colossians",
  "1 Thessalonians",
  "2 Thessalonians",
  "1 Timothy",
  "2 Timothy",
  "Titus",
  "Philemon",
  "Hebrews",
  "James",
  "1 Peter",
  "2 Peter",
  "1 John",
  "2 John",
  "3 John",
  "Jude",
  "Revelation",
];
