import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

function simpleSearch(text, item) {
  const prepared = item
    .replace(/\\/ig, '\\\\') // must be 1st
    .replace(/\./ig, "\\.")
    .replace(/\//ig, "\/")
    .replace(/\[/gi, "\\[")
    .replace(/\]/gi, "\\]")
    .replace(/-/gi, "\\-")
    .replace(/\s/, "\\s")
  if (!prepared.length) {
    return null;
  }
  return (
    new RegExp(
      prepared,
      "ig"
    ).exec(text)?.index ?? -1
  );
}
const SURROUNDING_CHARS = 20;
const MAX_MATCHES = 20;

export function Search({ mainObj }) {
  const [searchText, setSearchText] = useState("");
  const [results, setResults] = useState([]);
  const [touched, setTouched] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!searchText) {
      setResults([]);
      return;
    }

    const items = [];
    let matchCount = 0;

    for (let i = 0; i < mainObj.marks.length; i++) {
      const text = mainObj.marks[i].chunk;
      const posIdx = simpleSearch(text, searchText);
      if (posIdx >= 0) {
        const [s, e] = [posIdx, posIdx + searchText.length];
        const [sNice, eNice] = [
          Math.max(0, s - SURROUNDING_CHARS),
          Math.min(text.length, e + SURROUNDING_CHARS),
        ];
        matchCount += 1;
        items.push({
          idx: i,
          mark: mainObj.marks[i].idx,
          start: text.substring(s, sNice),
          fragment: text.substring(s, e),
          end: text.substring(e, eNice),
        });
        if (matchCount >= MAX_MATCHES) {
          break;
        }
      }
    }
    setResults(items);
  }, [searchText, mainObj]);

  return (
    <section>
      <Link to="/" className="decorative-font font-medium">
        the Pilgrim's Progress
      </Link>
      <h1 className="decorative-font font-large">Search</h1>
      <br />
      <input
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
          setTouched(true);
        }}
        className="big-input"
        placeholder="Search..."
      />
      <br />
      <br />
      {!results.length && touched ? (
        <h2 className="decorative-font" style={{ opacity: 0.25 }}>
          No results
        </h2>
      ) : null}
      {results.map((result, i) => (
        <div
          className="search-result"
          onClick={() => {
            navigate(`/?query=${result.fragment}&mark=${result.mark}`);
          }}
          key={result.fragment + "-" + i}
        >
          ...{result.start}
          <strong>{result.fragment}</strong>
          {result.end}...
        </div>
      ))}
      {results.length === MAX_MATCHES ? (
        <>
          <br />
          <h2 className="decorative-font" style={{ opacity: 0.25 }}>
            More results...
          </h2>
          <br />
        </>
      ) : null}
    </section>
  );
}
