import { useEffect, useState } from "react";
import { places } from "../general/static";
import { capitaliseWords, useScrollToTop } from "../general/utils";
import { Sparkline, SPARKLINE_HEIGHT } from "../components/Sparkline";
import { useScreenSizeKey } from "../general/utils";
import { Link } from "react-router-dom";

export function Places({ mainObj }) {
  const [placesList, setPlacesList] = useState([]);
  const screenSizeKey = useScreenSizeKey()
  useScrollToTop()

  useEffect(() => {
    if (!mainObj) {
      return;
    }
    let items = [];
    for (const obj of places) {
      items.push({
        ...obj,
        count: mainObj.placeCounts[obj.name] || 0,
        indices: mainObj.placeIndices[obj.name] || [],
        byGroup: mainObj.placeIndicesByGroup[obj.name],
      });
    }

    items.sort((a, b) => {
      const aFirstIdx = a.indices[0];
      const bFirstIdx = b.indices[0];
      if (aFirstIdx !== undefined && bFirstIdx !== undefined) {
        return aFirstIdx - bFirstIdx;
      }
      return b.count - a.count;
    });
    setPlacesList(items);
  }, [mainObj]);

  return (
    <section>
      <Link to="/" className="decorative-font font-medium">
        the Pilgrim's Progress
      </Link>
      <h1 className="decorative-font font-large">Places</h1>

      {placesList.map((obj) => {
        const name = obj.display || obj.name;
        return (
          <div
            key={obj.name}
            className="sparkline-index"
            style={{ height: SPARKLINE_HEIGHT }}
          >
            <div className="part-1 decorative-font">
              {capitaliseWords(name)}
            </div>
            <div className="part-2">
              <Sparkline
                points={obj.byGroup}
                key={screenSizeKey}
                query={name}
              />
            </div>
          </div>
        );
      })}
    </section>
  );
}
