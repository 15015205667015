import { createContext, useContext, useEffect, useState } from "react";

const BibleContext = createContext({
  bibleData: null,
});

export const BibleContextProvider = ({ children }) => {
  const [bibleData, setBibleData] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      fetch("/t_kjv.json")
        .then((d) => d.text())
        .then((d) => setBibleData(JSON.parse(d).resultset.row));
    }, 1500);
  }, []);

  return (
    <BibleContext.Provider value={{ bibleData }}>
      {children}
    </BibleContext.Provider>
  );
};

export const useBibleContext = () => useContext(BibleContext);
