import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import { mainAnalysis } from "./general/analysis";
import CookieConsent from "react-cookie-consent";

import "./App.scss";
import { Chars } from "./pages/Chars";
import { Places } from "./pages/Places";
import { Book } from "./pages/Book";
import { Bible } from "./pages/Bible";
import { Map } from "./pages/Map";
import { Search } from "./pages/Search";
import { BibleContextProvider } from "./general/BibleContext";
import { About } from "./pages/About";
import {
  useScreenSizeKey,
  useScrollToMark,
  useThrottle,
} from "./general/utils";

const Header = ({ currentBookmark, setBookmark }) => {
  const menuRef = useRef();
  const [showingMenu, setShowingMenu] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    setShowingMenu(false);
  }, [pathname]);

  useEffect(() => {
    const fn = (e) => {
      if (showingMenu && !menuRef.current.contains(e.target)) {
        setShowingMenu(false);
      }
    };
    document.body.addEventListener("click", fn);
    return () => document.body.removeEventListener("click", fn);
  }, [showingMenu, menuRef]);

  const scrollToMark = useScrollToMark();

  return (
    <>
      <header
        className="main-header"
        style={{
          position: "fixed",
          top: 0,
          width: "100%"
        }}
      >
        <div className="wrapper">
          <div
            onClick={() => setShowingMenu(!showingMenu)}
            className={`offset-on-desktop main-menu ${
              showingMenu ? "open" : ""
            }`}
            style={{
              width: showingMenu ? 200 : 50,
            }}
          >
            <div ref={menuRef}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                className="bi bi-list"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                />
              </svg>
            </div>
            <nav
              style={{
                display: showingMenu ? "flex" : "none",
                flexDirection: "column",
              }}
            >
              <Link to="/">Book</Link>
              {pathname === "/" ? (
                <>
                  <Link
                    to="#"
                    onClick={() => {
                      if (!!currentBookmark) {
                        scrollToMark(currentBookmark);
                      } else {
                        alert('No bookmark found');
                      }
                    }}
                  >
                    &nbsp;&nbsp;Go to bookmark
                  </Link>
                  <Link
                    to="#"
                    onClick={() => {
                      setBookmark(null);
                    }}
                  >
                    &nbsp;&nbsp;Clear bookmark
                  </Link>
                </>
              ) : null}
              <Link to="/map">Map</Link>
              <Link to="/search">Search</Link>
              <Link to="/about">About</Link>
              <h3 className="subtitle">Lookups</h3>
              <Link to="/bible_refs">Bible References</Link>
              <Link to="/characters">Characters</Link>
              <Link to="/places">Places</Link>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

function Main() {
  const [mainObj, setMainObj] = useState();
  const [altObj, setAltObj] = useState();
  const screenSize = useScreenSizeKey();
  const { pathname } = useLocation();

  const contentRef = useRef();

  const updateBorder = useCallback(() => {
    if (!contentRef.current) {
      return;
    }
    const els = [
      document.getElementById("decoration-1"),
      document.getElementById("decoration-2"),
    ];
    const height = contentRef.current.offsetHeight;
    const patSize = 30;
    const scaledHeight = Math.floor(height / patSize) * patSize - patSize * 3;
    els.forEach((el) => {
      el.style.opacity = "0.25";
      el.children[1].style.height = scaledHeight + "px";
    });
  }, [contentRef.current?.offsetHeight, screenSize, pathname]);

  useThrottle(updateBorder, 100);

  useEffect(() => {
    (async () => {
      const txt = await fetch("/text-edited.txt").then((d) => d.text());
      setMainObj(mainAnalysis(txt));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const txt = await fetch("/conversion-15.txt").then((d) => d.text());
      setAltObj(mainAnalysis(txt));
    })();
  }, []);

  const defaultBookmark = useMemo(() => {
    return localStorage.getItem("_bookmark");
  }, []);

  const [currentBookmark, _setCurrentBookmark] = useState(defaultBookmark);

  const setBookmark = useCallback((v) => {
    _setCurrentBookmark(v);
    if (!!v) {
      localStorage.setItem("_bookmark", v);
    } else {
      localStorage.removeItem("_bookmark");
    }
  }, []);

  return (
    <>
      <Header currentBookmark={currentBookmark} setBookmark={setBookmark} />
      <section className="main-content wrapper" ref={contentRef}>
        <div id="decoration-1" className="decoration-surround left">
          <div className="decoration pre"></div>
          <div className="decoration mid"></div>
          <div className="decoration post"></div>
        </div>
        <div id="decoration-2" className="decoration-surround right">
          <div className="decoration pre"></div>
          <div className="decoration mid"></div>
          <div className="decoration post"></div>
        </div>
        <Routes>
          <Route path="/map" element={<Map mainObj={mainObj} />} />
          <Route path="/search" element={<Search mainObj={mainObj} />} />
          <Route path="/bible_refs" element={<Bible mainObj={mainObj} />} />
          <Route path="/characters" element={<Chars mainObj={mainObj} />} />
          <Route path="/places" element={<Places mainObj={mainObj} />} />
          <Route path="/about" element={<About mainObj={mainObj} />} />
          <Route
            path="/"
            default
            element={
              <Book
                mainObj={mainObj}
                currentBookmark={currentBookmark}
                setBookmark={setBookmark}
              />
            }
          />
          <Route
          path="/tolkien-beta"
          default
          element={
            <Book
              mainObj={altObj}
              alt
              currentBookmark={currentBookmark}
              setBookmark={setBookmark}
            />
          }
        />
        </Routes>
      </section>
    </>
  );
}

const CookieConsentControl = () => (
  <CookieConsent
    buttonText="OK!"
    cookieName="cookie-consent"
    style={{ background: "rgba(10,10,10,0.8)" }}
    buttonStyle={{
      background: "#f4d7ac",
      borderRadius: 3,
      color: "#333",
      fontWeight: "bold",
    }}
    expires={150}
  >
    Please be advised that this website uses cookies 🍪.
  </CookieConsent>
);

export default function App() {
  return (
    <BibleContextProvider>
      <BrowserRouter>
        <Main />
        <CookieConsentControl />
      </BrowserRouter>
    </BibleContextProvider>
  );
}
