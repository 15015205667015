

import { useEffect, useRef, useState, useCallback } from "react";

export function capitalise(w) {
  return w.substring(0, 1).toUpperCase() + w.substring(1).toLowerCase();
}

export function capitaliseWords(w) {
  return w.split(" ").map(capitalise).join(" ");
}

export const clamp = (v, min, max) => Math.min(max, Math.max(min, v));

export const useScreenSizeKey = () => {
  const [screenSizeKey, setScreenSizeKey] = useState(1);

  useEffect(() => {
    const listenerFn = () => setScreenSizeKey(screenSizeKey + 1);
    window.addEventListener("resize", listenerFn);
    return () => window.removeEventListener("resize", listenerFn);
  });

  return screenSizeKey;
};

export const useThrottle = (fn, timeout) => {
  const timeoutId = useRef(0);

  useEffect(() => {
    timeoutId.current = setTimeout(fn, timeout);
    return () => clearTimeout(timeoutId.current);
  }, [fn, timeout, timeoutId]);
};


export const useScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
}

export const useScrollToMark = () => {
  return useCallback((mark) => {
    const validMark = !!mark && mark.match(/^\d{1,3}/);

    if (validMark) {
      const el = document.getElementById(`mark-${mark}`);
      window.scrollTo(0, el.offsetTop - 20);
    }
  }, []);
}

export const BookmarkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-bookmark-star"
    viewBox="0 0 16 16"
  >
    <path d="M7.84 4.1a.178.178 0 0 1 .32 0l.634 1.285a.178.178 0 0 0 .134.098l1.42.206c.145.021.204.2.098.303L9.42 6.993a.178.178 0 0 0-.051.158l.242 1.414a.178.178 0 0 1-.258.187l-1.27-.668a.178.178 0 0 0-.165 0l-1.27.668a.178.178 0 0 1-.257-.187l.242-1.414a.178.178 0 0 0-.05-.158l-1.03-1.001a.178.178 0 0 1 .098-.303l1.42-.206a.178.178 0 0 0 .134-.098L7.84 4.1z" />
    <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z" />
  </svg>
);

export const LinkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-link"
    viewBox="0 0 16 16"
  >
    <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
    <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
  </svg>
);

export const DownArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-arrow-down"
    viewBox="0 0 16 16"
  >
    <path
      fillRule="evenodd"
      d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
    />
  </svg>
);