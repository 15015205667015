import { useEffect, useState } from "react";
import { BibleBooks } from "../general/static";
import { capitaliseWords, useScrollToTop } from "../general/utils";
import { Sparkline, SPARKLINE_HEIGHT } from "../components/Sparkline";
import { useScreenSizeKey } from "../general/utils";
import { Link } from "react-router-dom";

export function Bible({ mainObj }) {
  const [refsList, setRefsList] = useState([]);
  useScrollToTop()

  useEffect(() => {
    if (!mainObj) {
      return;
    }
    setRefsList(mainObj.bibleDataByGroup);
  }, [mainObj]);

  const screenSizeKey = useScreenSizeKey()

  return (
    <section>
      <Link to="/" className="decorative-font font-medium">
        the Pilgrim's Progress
      </Link>
      <h1 className="decorative-font font-large">Bible References</h1>

      {refsList.map((obj) => {
        return (
          <div
            key={obj.bibleIndex}
            className="sparkline-index"
            style={{
              height: SPARKLINE_HEIGHT,
              opacity: obj.ppMarks.length === 0 ? 0.25 : undefined,
            }}
          >
            <div className="part-1 decorative-font">
              {capitaliseWords(BibleBooks[obj.bibleIndex])}
            </div>
            <div className="part-2">
              <Sparkline points={obj.grouped} key={screenSizeKey} />
            </div>
          </div>
        );
      })}
    </section>
  );
}
