import { useEffect, useState } from "react";
import { chars, extraChars } from "../general/static";
import { Sparkline, SPARKLINE_HEIGHT } from "../components/Sparkline";
import { capitaliseWords, useScrollToTop } from "../general/utils";
import { useScreenSizeKey } from "../general/utils";
import { Link } from "react-router-dom";

export function Chars({ mainObj }) {
  const [charList, setCharList] = useState([]);
  useScrollToTop()

  const screenSizeKey = useScreenSizeKey()

  useEffect(() => {
    if (!mainObj) {
      return;
    }
    let items = [];
    for (const k in chars) {
      items.push({
        name: chars[k],
        lookup: k,
        count: mainObj.charCounts[k] || 0,
        indices: mainObj.charIndices[k] || [],
        byGroup: mainObj.charIndicesByGroup[k],
      });
    }
    for (const k of extraChars) {
      items.push({
        name: k,
        lookup: k,
        count: mainObj.charCounts[k] || 0,
        indices: mainObj.charIndices[k] || [],
        byGroup: mainObj.charIndicesByGroup[k],
      });
    }

    items.sort((a, b) => {
      const aFirstIdx = a.indices[0];
      const bFirstIdx = b.indices[0];
      if (aFirstIdx !== undefined && bFirstIdx !== undefined) {
        return aFirstIdx - bFirstIdx;
      }
      return b.count - a.count;
    });
    setCharList(items);
  }, [mainObj]);

  return (
    <section>
      <Link to="/" className="decorative-font font-medium">
        the Pilgrim's Progress
      </Link>
      <h1 className="decorative-font font-large">Characters</h1>

      {charList.map((obj) => {
        return (
          <div
            key={obj.name}
            className="sparkline-index"
            style={{ height: SPARKLINE_HEIGHT }}
          >
            <div className="part-1 decorative-font">
              {capitaliseWords(obj.name)}
            </div>
            <div className="part-2">
              <Sparkline
                points={obj.byGroup}
                key={screenSizeKey}
                query={obj.name}
              />
            </div>
          </div>
        );
      })}
    </section>
  );
}
