import { useCallback, useRef } from "react";
import * as d3 from "d3";
import { useNavigate } from "react-router";
import { clamp, useScreenSizeKey, useScrollToTop, useThrottle } from "../general/utils";
import { divisions, mapPlaces, totalMarks } from "../general/static";
import { Link } from "react-router-dom";

const makePath = (width) => {
  const unscaledPoints = [
    [{ x: 130.402, y: 494.336 }],
    [
      { x: 256, y: 397 },
      { x: 170.5, y: 118.5 },
      { x: 408.5, y: 136 },
    ],
    [
      { x: 646.501, y: 153.5 },
      { x: 591.638, y: 321.74 },
      { x: 519, y: 379.5 },
    ],
    [
      { x: 436, y: 445.5 },
      { x: 398, y: 649 },
      { x: 643, y: 649 },
    ],
    [
      { x: 888, y: 649 },
      { x: 962.5, y: 449.5 },
      { x: 886.125, y: 147.421 },
    ],
  ];

  const scale = d3
    .scaleLinear()
    .domain([
      d3.min(unscaledPoints, (pts) => d3.min(pts, pt => pt.x)),
      d3.max(unscaledPoints, (pts) => d3.max(pts, pt => pt.x)),
    ])
    .range([0, width]);

  const points = unscaledPoints.map((pts) =>
    pts.map((pt) => ({
      x: scale(pt.x),
      y: scale(pt.y),
    }))
  );

  const combine = (pts) => pts.map((pt) => `${pt.x} ${pt.y}`).join(" ");
  const cCombine = (pts) => `C ${combine(pts)}`;

  return `M ${combine(points[0])} ${points.slice(1).map(cCombine).join(" ")}`;
};

const makeSubPath = (el, start, end) => {
  const parts = 50;
  const len = el.getTotalLength();
  const subPathLen = end - start;

  const path = d3.path();

  for (let i = 0; i <= parts; i++) {
    const relProp = (i / parts) * subPathLen + start;
    const { x, y } = el.getPointAtLength(relProp * len);
    if (i === 0) {
      path.moveTo(x, y);
    } else {
      path.lineTo(x, y);
    }
  }

  return path.toString();
};

const dist = (xy1, xy2) =>
  Math.sqrt(Math.pow(xy1.x - xy2.x, 2) + Math.pow(xy1.y - xy2.y, 2));

const makePathPointsLookup = (node) => {
  const len = node.getTotalLength();
  const resolution = totalMarks;

  return d3.range(0, resolution + 1).map((v) => {
    return {
      pct: v / resolution,
      pos: node.getPointAtLength((v / resolution) * len),
    };
  });
};

const findClosestPoint = (pt, lookup) =>
  lookup
    .map((obj) => ({
      ...obj,
      dist: dist(obj.pos, pt),
    }))
    .sort((a, b) => a.dist - b.dist)[0];

const findClosestPosition = (pct, lookup) =>
  lookup
    .map((obj) => ({
      ...obj,
      dist: Math.abs(obj.pct - pct),
    }))
    .sort((a, b) => a.dist - b.dist)[0];

const knightMark = mapPlaces.filter((o) => o.name === "House Beautiful")[0]
  .mark;

const updateUserPos = (svg, parent, imageSize, pt) => {
  const iconSize = imageSize * 0.6;

  svg.style("cursor", pt ? "pointer" : null);
  const focus = parent
    .selectAll(".focus")
    .data(pt ? [null] : [])
    .join("g")
    .attr("class", "focus")
    .attr("transform", pt ? `translate(${pt.pos.x}, ${pt.pos.y})` : "");

  focus
    .selectAll("image")
    .data([null])
    .join("image")
    .attr("width", iconSize)
    .attr("height", iconSize)
    .attr("x", -iconSize / 2)
    .attr("y", -iconSize / 2)
    .attr(
      "href",
      pt && pt.pct * totalMarks > knightMark
        ? "/assets-gen/knight2.jpg"
        : "/assets-gen/pilgrim2.jpg"
    );
};

function textEnhance(d, lineHeight, xOffset) {
  const parts = d.name.split(" ").reduce((prev, cur) => {
    if (cur.length <= 3) {
      return [...prev.slice(0, prev.length - 1), prev + " " + cur];
    }
    return prev.concat(cur);
  }, []);

  const initialOffset = parts.length > 1 ? lineHeight / 2 : 0;
  d3.select(this)
    .selectAll("tspan")
    .data(parts)
    .join("tspan")
    .text((v) => v)
    .attr("x", 0)
    .attr("dx", xOffset)
    .attr("dy", (d, i) => (i === 0 ? -initialOffset : lineHeight));
}

const mainRender = (el, navigate) => {
  const svg = d3.select(el);

  const width = el.parentElement.offsetWidth;
  const height = width * 0.8;
  const marginX = width * 0.08;
  const marginY = width * 0.08;

  const fontSize1 = clamp(width * 0.03, 10, 20);
  const fontSize2 = clamp(width * 0.02, 8, 15);
  const imageSize = clamp(width * 0.08, 40, 70);

  svg.attr("width", width).attr("height", height);

  const pathG = svg.select(".path-container");
  const placeG = svg.select(".place-container");
  const pointG = svg.select(".point-container");

  pathG.attr("transform", `translate(${marginX}, ${marginY})`);
  placeG.attr("transform", `translate(${marginX}, ${marginY})`);
  pointG.attr("transform", `translate(${marginX}, ${marginY})`);

  const path = pathG.selectAll("path").data([null]).join("path");

  path.attr("d", makePath(width - marginX * 2));

  pathG.select("path").style("fill", "none");

  const lookup = makePathPointsLookup(path.node());

  const divisionsInfo = divisions.slice(0, divisions.length).map((d, i) => {
    const start = i === 0 ? 0 : d.mark;
    const end = i === divisions.length - 1 ? totalMarks : divisions[i + 1].mark;
    const midpoint = (start + end) / 2;

    return {
      idx: i,
      name: d.name,
      start,
      end,
      midpoint: findClosestPosition(midpoint / totalMarks, lookup)?.pos,
      offsetY: d.mapLabelOffsetY
    };
  });

  const places = placeG
    .selectAll(".chapter-item")
    .data(divisionsInfo)
    .join("g")
    .attr("class", "chapter-item");

  places
    .selectAll("path")
    .data((d) => [d])
    .join("path")
    .attr("d", (d) =>
      makeSubPath(path.node(), d.start / totalMarks, d.end / totalMarks)
    )
    .style("fill", "none")
    .style("stroke", (d) =>
      d.idx % 2 === 0 ? "rgba(205, 149, 67, 0.15)" : "rgba(132, 103, 60, 0.15)"
    )
    .style("stroke-width", 20);

  places
    .selectAll(".text")
    .data((d) => [d])
    .join("g")
    .attr("class", "text")
    .attr("transform", (d) => `translate(${d.midpoint.x}, ${d.midpoint.y + (d.offsetY ? d.offsetY * width : 0)})`)
    .selectAll("text")
    .data((d) => [d])
    .join("text")
    .style("stroke", "white")
    .style("stroke-width", 3)
    .style("stroke-alignment", "outer")
    .attr("paint-order", "stroke")
    .style("stroke-linecap", "butt")
    .style("stroke-linejoin", "miter")
    .attr("class", "decorative-font")
    .attr("text-anchor", "middle")
    .attr("dominant-baseline", "middle")
    .style("font-size", fontSize1 + "px")
    .each(function (d) {
      textEnhance.call(this, d, fontSize1 * 0.9);
    });

  const pointsInfo = mapPlaces.reverse().map((d, i) => ({
    ...d,
    idx: i,
    pos: findClosestPosition(d.mark / totalMarks, lookup)?.pos,
  }));

  const points = pointG
    .selectAll(".point-item")
    .data(pointsInfo)
    .join("g")
    .attr("class", "point-item")
    .attr("transform", (d) => `translate(${d.pos.x}, ${d.pos.y})`);

  points
    .selectAll("image")
    .data((d) => (d.asset ? [d] : []))
    .join("image")
    .attr("width", imageSize)
    .attr("height", imageSize)
    .attr("x", -imageSize / 2)
    .attr("y", -imageSize / 2)
    .attr("href", (d) => `/assets-gen/${d.asset}.jpg`);

  points
    .selectAll("circle")
    .data((d) => (d.asset ? [] : [d]))
    .join("circle")
    .attr("cx", 0)
    .attr("cy", 0)
    .attr("r", 4);

  points
    .selectAll("text")
    .data((d) => (d.iconOnly ? [] : [d]))
    .join("text")
    .attr("text-anchor", (d) => (d.idx % 2 === 0 ? "start " : "end"))
    .style("stroke", "white")
    .style("stroke-width", 1)
    .style("stroke-alignment", "outer")
    .attr("paint-order", "stroke")
    .style("stroke-linecap", "butt")
    .style("stroke-linejoin", "miter")
    .attr("class", "decorative-font")
    .style("font-size", fontSize2 + "px")
    .each(function (d) {
      textEnhance.call(this, d, fontSize2 * 0.9, d.idx % 2 === 0 ? 20 : -20);
    });

  const offsetTop = el.parentElement.offsetTop;

  updateUserPos(svg, placeG, imageSize);

  const closetPt = (e) => {
    const pt = findClosestPoint(
      {
        x: e.layerX - marginX,
        y: e.layerY - marginY - offsetTop,
      },
      lookup
    );
    return pt.dist < width / 15 ? pt : undefined;
  };

  svg
    .on("mousemove", (e) => {
      const pt = closetPt(e);
      updateUserPos(svg, placeG, imageSize, pt);
    })
    .on("click", (e) => {
      const pt = closetPt(e);
      if (pt) {
        navigate(`/?mark=${Math.round(pt.pct * totalMarks)}`);
      }
    });
};

export function Map({ mainObj }) {
  const svgRef = useRef();
  const wwKey = useScreenSizeKey();
  const navigate = useNavigate();
  useScrollToTop()

  const udpateFn = useCallback(() => {
    if (!svgRef.current) {
      return;
    }
    mainRender(svgRef.current, navigate);
  }, [svgRef, wwKey, navigate]);

  useThrottle(udpateFn, 50);

  return (
    <section>
      <Link to="/" className="decorative-font font-medium">
        the Pilgrim's Progress
      </Link>
      <h1 className="decorative-font font-large">Map</h1>
      <div>
        <svg ref={svgRef}>
          <g className="path-container"></g>
          <g className="point-container"></g>
          <g className="place-container"></g>
        </svg>
      </div>
    </section>
  );
}
