import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { divisions } from "../general/static";
import { useNavigate } from "react-router";

export const SPARKLINE_HEIGHT = 60;
const MARGIN = 5;

const DIVS_LOOKUP = divisions.slice().reverse();

const mainRender = (
  width,
  svgEl,
  points,
  linePos,
  setLinePos,
  navigate,
  query
) => {
  const svg = d3.select(svgEl);
  svg.attr("width", width);

  const lastPoint = points[points.length - 1].x1;

  const yScale = d3
    .scaleLinear()
    .domain([0, d3.max(points.map((p) => p.length))])
    .range([SPARKLINE_HEIGHT - MARGIN * 2, 0]);

  const xScale = d3
    .scaleLinear()
    .domain([points[0].x0, points[points.length - 1].x1])
    .range([0, width - MARGIN * 2]);

  const line = d3
    .line()
    .curve(d3.curveBasis)
    .x((d) => xScale(d3.mean([d.x0, d.x1])))
    .y((d) => yScale(d.length || 0));

  const g = svg.select("g");

  g.selectAll("path")
    .data([points])
    .join("path")
    .attr("d", (d) =>
      line(
        [{ x0: 0, x1: 0 }].concat(d).concat({ x0: lastPoint, x1: lastPoint })
      )
    )
    .attr("fill", "none")
    .attr("stroke", "#333")
    .attr("stroke-width", "2px");

  const indicator = g
    .selectAll(".indicator")
    .data(linePos !== undefined ? [linePos] : [])
    .join("g")
    .attr("class", "indicator")
    .attr("transform", (d) => `translate(${xScale(d)}, 0)`);

  indicator
    .selectAll("line")
    .data([null])
    .join("line")
    .attr("x1", 0)
    .attr("x2", 0)
    .attr("y1", 0)
    .attr("y2", yScale.range()[0])
    .style("stroke", "#333")
    .style("stroke-width", "2px");

  const textStart = linePos < lastPoint / 2;

  indicator
    .selectAll("text")
    .data([null])
    .join("text")
    .style("dominant-baseline", "hanging")
    .style("text-anchor", textStart ? "start" : "end")
    .style("font-size", "14px")
    .style("paint-order", "stroke")
    .style("stroke", "white")
    .style("stroke-width", "2px")
    .style("font-weight", "bold")
    .attr("x", textStart ? 5 : -5)
    .text(
      (
        DIVS_LOOKUP.filter((d) => d.mark <= linePos)[0] ||
        DIVS_LOOKUP[DIVS_LOOKUP.length - 1]
      )?.name
    );

  svg
    .on("mousemove", (e) => {
      setLinePos(xScale.clamp(true).invert(e.offsetX - MARGIN));
    })
    .on("mouseleave", () => {
      setLinePos(undefined);
    })
    .on("click", () => {
      if (linePos === undefined) {
        return;
      }
      const flat = [];
      for (const p of points) {
        for (const mark of p) {
          flat.push({
            delta: Math.abs(linePos - mark),
            mark,
          });
        }
      }
      const closest = flat.sort((a, b) => a.delta - b.delta)[0];
      if (closest.delta <= 25) {
        if (query) {
          navigate(`/?query=${query}&mark=${closest.mark}`);
        } else {
          navigate(`/?mark=${closest.mark}`);
        }
      }
    });
};

export function Sparkline({ points, query }) {
  const contRef = useRef();
  const svgRef = useRef();
  const [linePos, setLinePos] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (!contRef.current || !svgRef.current || !points) {
      return;
    }

    mainRender(
      contRef?.current?.offsetWidth,
      svgRef.current,
      points,
      linePos,
      setLinePos,
      navigate,
      query
    );
  }, [contRef, svgRef, points, linePos, setLinePos, navigate, query]);

  return (
    <div ref={contRef}>
      <svg
        ref={svgRef}
        width="100%"
        height={SPARKLINE_HEIGHT}
        style={{ cursor: "pointer" }}
      >
        <g transform={`translate(${MARGIN},${MARGIN})`}></g>
      </svg>
    </div>
  );
}
