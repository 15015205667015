import React, { useEffect, useMemo, useRef, useState } from "react";
import { BibleBooks, chars, divisions } from "../general/static";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { bcvBibleBooks, simpleSearch } from "../general/analysis";
import { simpleSearchAll } from "../general/analysis";
import { BookmarkIcon, DownArrowIcon, LinkIcon, useScreenSizeKey, useScrollToMark } from "../general/utils";
import { useBibleContext } from "../general/BibleContext";

function HighlightedText({ text, highlight, highlightClass }) {
  const matches = simpleSearchAll(text, highlight);

  if (!matches.length) {
    return <span>{text}</span>;
  }

  let keyIdx = 0;
  let idx = 0;
  const parts = [];
  for (const match of matches) {
    if (match.index > idx) {
      parts.push(<span key={keyIdx}>{text.slice(idx, match.index)}</span>);
    }
    parts.push(
      <span key={keyIdx + 1} className={highlightClass}>
        {text.slice(match.index, match.index + highlight.length)}
      </span>
    );
    idx = match.index + highlight.length;
    keyIdx += 2;
  }

  if (idx < text.length) {
    parts.push(<span key={keyIdx + 1}>{text.slice(idx, text.length)}</span>);
  }

  return <>{parts}</>;
}

const SectionItem = ({
  obj,
  o,
  newDivision,
  j,
  highlight,
  highlightActive,
}) => {
  let text = o.chunk.substring(obj.idx, obj.endIdx);
  let className = "";
  let haveHighlight = false;

  if (obj.type === "character-simple") {
    text = chars[text] || "missing";
  }

  if (!!highlight) {
    const idx = simpleSearch(text, highlight);
    if (idx >= 0) {
      haveHighlight = true;
      className = highlightActive ? "highlight" : "";
    }
  }

  let out = null;
  switch (obj.type) {
    case "quote":
      out = <span className={"text-quote " + className}>{text}</span>;
      break;
    case "character-simple":
      out = (
        <>
          <div style={{ height: 10 }} />
          <span className={"text-char-simple " + className}>{text}</span>
        </>
      );
      break;
    case "character":
      out = (
        <span
          style={{
            textDecoration: "underline",
            textDecorationColor: "rgba(0,0,0,0.1)",
          }}
          className={"text-char " + className}
        >
          {text}
        </span>
      );
      break;
    case "place":
      out = <span className={"text-place " + className}>{text}</span>;
      break;
    case "bible":
      let tooltipContent = "";
      let isShort = true;
      if (!obj.bible) {
        tooltipContent = "Loading...";
      } else {
        const short = obj.bible.map(({ ref }) => ref).join(", ");
        const long =
          obj.bible.length === obj.bible.filter(({ text }) => !!text).length
            ? obj.bible.map(({ ref, text }) => `(${ref}) ${text}`).join("\n\n")
            : short;
        isShort = long.length > 300;
        tooltipContent = isShort ? short : long;
      }

      out = (
        <strong data-tooltip={tooltipContent} className="tooltip text-bible">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-bookmarks"
            viewBox="0 0 16 16"
          >
            <path d="M2 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L7 13.101l-4.223 2.815A.5.5 0 0 1 2 15.5V4zm2-1a1 1 0 0 0-1 1v10.566l3.723-2.482a.5.5 0 0 1 .554 0L11 14.566V4a1 1 0 0 0-1-1H4z" />
            <path d="M4.268 1H12a1 1 0 0 1 1 1v11.768l.223.148A.5.5 0 0 0 14 13.5V2a2 2 0 0 0-2-2H6a2 2 0 0 0-1.732 1z" />
          </svg>
        </strong>
      );
      break;
    case "base":
      if (haveHighlight) {
        out = (
          <HighlightedText
            text={text}
            highlight={highlight}
            highlightClass={className}
          />
        );
      } else if (newDivision && j === 0) {
        out = (
          <>
            <span className="drop-cap decorative-font">{text[0]}</span>
            <span>{text.slice(1)}</span>
          </>
        );
      } else {
        out = <span>{text}</span>;
      }
      break;
    case "poem":
      const items = [];
      text
        .replace(/[<>]/g, "")
        .split("\n")
        .forEach((chunk1, i) => {
          const chunk2 = chunk1.replace(/^\s+/gi, "").replace(/\s+$/gi, "");
          if (!chunk2.length) {
            return;
          }
          items.push(
            <span key={i}>
              {i === 0 ? null : null}
              {chunk1}
              <br />
            </span>
          );
        });
      out = <div className="poem">{items}</div>;
      break;
    case "break":
      out = <div className="main-break"></div>;
      break;
    default:
      throw new Error("not recognised");
  }
  return <React.Fragment key={j}>{out}</React.Fragment>;
};

export function Book({ mainObj, currentBookmark, setBookmark, alt }) {
  const loadedRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [highlight, setHighlight] = useState("");
  const [highlightActive, setHighlightActive] = useState(false);
  const { pathname } = useLocation();

  const [qpInit, setQpInit] = useState(false);
  const [contentInit, setContentInit] = useState(false);
  const [bibleReady, setBibleReady] = useState(false);

  const { bibleData } = useBibleContext();

  useEffect(() => {
    if (!mainObj || !bibleData) {
      return;
    }

    mainObj.marks.forEach((o) => {
      o.tokens.forEach((obj) => {
        if (obj.type !== "bible") {
          return;
        }
        obj.bible = obj.obj.map((obj2) => {
          const { start, end } = obj2;
          if (start.b !== end.b || start.c !== end.c) {
            throw new Error("invalid range");
          }
          const bookIdx = bcvBibleBooks.indexOf(start.b);
          if (bookIdx === -1) {
            throw new Error("did not recognise bible book");
          }
          const origBook = BibleBooks[bookIdx];
          // start, end, .b, .c
          const outRef =
            start.v !== end.v
              ? `${origBook} ${start.c}:${start.v}-${end.v}`
              : `${origBook} ${start.c}:${start.v}`;

          if (obj2.type === "bc" || obj2.type === "integer") {
            // whole chapter or multiple chapters
            return { ref: outRef };
          }
          const verses = bibleData.filter(
            ({ field }) =>
              field[1] === bookIdx + 1 &&
              start.c === field[2] &&
              start.v <= field[3] &&
              field[3] <= end.v
          );
          const text = verses.map((v) => v.field[4]).join("\n");
          return { ref: outRef, text };
        });
      });
    });
    setBibleReady(true);
  }, [mainObj, bibleData]);

  const widthKey = useScreenSizeKey();

  const blockPositions = useMemo(() => {
    if (!contentInit) {
      return;
    }
    return [...document.querySelectorAll(".book-block")].map((el) => ({
      mark: el.getAttribute("id").split("-")[1],
      pos: el.offsetTop,
    }));
  }, [widthKey, contentInit]);

  useEffect(() => {
    const query = searchParams.get("query");

    if (!blockPositions) {
      return;
    }

    let timerId = 0;
    const firstMark = "" + mainObj.marks[0].idx;

    const timerFn = () => {
      const yOffset = window.scrollY;
      for (let i = 0; i < blockPositions.length; i++) {
        if (blockPositions[i].pos - yOffset + 100 > 0) {
          const currentMark = blockPositions[i].mark;
          const mark = currentMark !== firstMark ? currentMark : null;
          const params = {};

          if (!!query) {
            params["query"] = query;
          }
          if (!!mark) {
            params["mark"] = mark;
          }

          setSearchParams(params, {
            replace: true,
          });
          return false;
        }
      }
    };

    const fn = () => {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(timerFn, 50);
    };
    window.addEventListener("scroll", fn);
    return () => window.removeEventListener("scroll", fn);
  }, [searchParams, blockPositions, setSearchParams, mainObj]);

  useEffect(() => {
    if (mainObj?.marks && !!loadedRef.current) {
      setContentInit(true);
    }
  }, [mainObj, loadedRef]);

  const scrollToMark = useScrollToMark();

  // highlighting
  useEffect(() => {
    if (qpInit || !contentInit) {
      return;
    }
    setQpInit(true);
    const query = searchParams.get("query");

    const mark = searchParams.get("mark");
    if (mark) {
      scrollToMark(mark);
    } else if (currentBookmark) {
      scrollToMark(currentBookmark);
    }

    if (!!query) {
      setHighlight(query);
      setHighlightActive(true);

      setTimeout(() => {
        if (window.location.pathname !== "/") {
          // user has navigated, still a possible bug if user navigates back
          return;
        }
        setSearchParams({}, { replace: true });
        setHighlightActive(false);
      }, 10 * 1000);
    }
  }, [
    qpInit,
    searchParams,
    contentInit,
    setSearchParams,
    pathname,
    scrollToMark,
  ]);

  if (!mainObj) {
    return null;
  }

  return (
    <section key={bibleReady ? "ready" : "not-ready"}>
      {alt && (
        <Link to="/" className="decorative-font font-medium">
          back
        </Link>
      )}
      {alt ? (
        <h1 className="decorative-font font-large">In the style of Tolkien</h1>
      ) : (
        <>
          <h1 className="decorative-font font-large">the Pilgrim's Progress</h1>
          <h3 className="decorative-font font-medium">by John Bunyan</h3>
        </>
      )}
      {alt && (
        <>
          <p>
            <strong>The Pilgrim's Progress</strong> in the style of <strong>Tolkien</strong>, courtesy of{" "}
            <strong><a href="https://chat.openai.com/" target="_blank">
              ChatGPT
            </a></strong>
          </p>
        </>
      )}
      <br />
      <ul>
        <li>Read the whole book, simply scroll. {<DownArrowIcon />}</li>
        <li>Your scroll position can be shared as a link {<LinkIcon />}</li>
        <li>
          Double click on the text to set a bookmark in the web-app.{" "}
          {<BookmarkIcon />}
        </li>
      </ul>
      {alt && (
        <>
          <br />
          <p>
            AI technology may soon enable a time when Christians can filter
            out much of the mainstream sinful content in media. This is an
            experiment using ChatGPT to convert the Pilgrim's Progress
            into a style like that of Tolkien, giving a more elegant literary style
            to the brilliant classic while avoiding orcs and wizards!
          </p>
          <br />
          <p>
            There are likely to be mistakes in content and style in this, I have
            not proof read it.
          </p>
          <br />
          <p>
            Your feedback is welcome, you can contact me on the{" "}
            <Link to="/about">About</Link> page.
          </p>
        </>
      )}

      {mainObj && mainObj.marks
        ? mainObj.marks.map((o, i) => {
            const stringIdx = "" + o.idx;
            const newDivision = divisions.filter((d) => d.mark === o.idx)[0];

            return (
              <React.Fragment key={o.idx}>
                {newDivision && (
                  <>
                    {i > 0 ? (
                      <div className="chapter-end text-shadow">~</div>
                    ) : null}
                    <br />
                    <h3 className="chapter decorative-font">
                      <div className="chapter-inner">
                        <span className="caret">
                          <span>▾</span>
                        </span>
                        <select
                          value={newDivision.mark}
                          onChange={(e) => {
                            scrollToMark(e.target.value);
                          }}
                        >
                          {divisions.map((div) => {
                            return (
                              <option key={div.mark} value={div.mark}>
                                {div.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </h3>
                    <br />
                  </>
                )}
                <section
                  ref={loadedRef}
                  className={
                    "book-block anim-highlight " +
                    (currentBookmark === stringIdx ? "bookmark" : "")
                  }
                  id={"mark-" + stringIdx}
                  onDoubleClick={() => {
                    if (
                      stringIdx !== currentBookmark &&
                      !!window.confirm(alt ? "Set bookmark here? This will override your other bookmark" : "Set bookmark here?")
                    ) {
                      setBookmark(stringIdx);
                    }
                  }}
                >
                  <div className="hover-bookmark"></div>
                  {o.tokens.map((obj, j) => {
                    return (
                      <SectionItem
                        key={`${i}-${j}`}
                        o={o}
                        newDivision={newDivision}
                        obj={obj}
                        j={j}
                        highlight={highlight}
                        highlightActive={highlightActive}
                      />
                    );
                  })}
                </section>
              </React.Fragment>
            );
          })
        : null}
      <br />
      <a href="https://www.PilgrimsAllegory.com">www.PilgrimsAllegory.com</a>
      <br />
      <br />
      <strong>S.D.G.</strong>
    </section>
  );
}
